const logotext = "INDK";
const meta = {
    title: "INDK",
    description: "Desarrollo web en Ciudad Juárez",
};

const introdata = {
    title: "¡Hola, mi nombre es Edkar!",
    animated: {
        first: "Soy desarrollador de Software",
        second: "Pensador libre",
        third: "¡Bienvenido!",
    },
    description: "Vivo en la frontera más hermosa del mundo (Ciudad Juárez, Chihuahua, México)",
    your_img_url: "https://source.unsplash.com/collection/3630762",
};

const dataabout = {    
    title: "Un poco sobre mi",
    aboutme: "Después de 10 años en el desarrollo de software, se podría decir que soy un experto en el arte de hacer que las cosas parezcan fáciles. Desde la maquiladora hasta la medicina, he logrado impresionar a todo tipo de clientes con mi capacidad para crear programas que nadie más entiende. A veces incluso yo mismo me sorprendo de cómo logré hacer que todo funcione. Si necesitas un mago del código, ¡No busques más!",
};
const worktimeline = [{
        jobtitle: "Cloud Developer",
        where: "iTJuana - ATEC",
        date: "2023",
    },
    {
        jobtitle: "Software Developer",
        where: "Accenture - Foxconn",
        date: "2022",
    },
    {
        jobtitle: "Software Analyst",
        where: "GMEX",
        date: "2021",
    },
    {
        jobtitle: "Software Developer",
        where: "Foxconn",
        date: "2019 -2020",
    },
];

const skills = [{
        name: "PHP",
        value: 90,
    },
    {
        name: ".NET (C#)",
        value: 85,
    },
    {
        name: "Databases (MySQL, MSQL, Oracle)",
        value: 90,
    },
    {
        name: "React & VUE",
        value: 75,
    },
    {
        name: "Flutter",
        value: 83,
    },
];

const services = [{
        title: "Backend",
        description: "Desarrollo APIs en diferentes lenguajes para distintos propósitos, como recursos humanos, finanzas, producción, almacenamiento, taller y control de calidad.",
    },
    {
        title: "Mobile Apps",
        description: "Tengo experiencia desarrollando en esta tecnología, específicamente en las áreas de recursos humanos, venta y distribución de gas.",
    },
    {
        title: "Frontend",
        description: "He participado en el desarrollo de diversos frontends, incluyendo páginas web para empresas de taekwondo, la industria maquiladora y organizaciones sin fines de lucro.",
    },
];

const dataportfolio = [{
        img: "https://source.unsplash.com/collection/9402084",
        description: "Pagina web y sistema de control de la escuela de Taekwondo.",
        link: "https://ott-usa.com",
    },
    {
        img: "https://source.unsplash.com/collection/938304",
        description: "Lymer, formacion educativa, sistema de apoyo educacional y pagina Web",
        link: "https://ilymer.com",
    },
    {
        img: "https://source.unsplash.com/collection/9042679",
        description: "CPB (colegio panamericano bilingue) sistema de control de califiaciones.",
        link: "http://cpb.sistemacv.com",
    },
    {
        img: "https://source.unsplash.com/collection/0O83NcbpI78",
        description: "BECLEM pagina Web y sistema ERP.",
        link: "https://beclemind.com/",
    },
    {
        img: "https://source.unsplash.com/collection/3863470",
        description: "VGAD un sistema de recursos humanos.",
        link: "http://vgad.sistemascv.com/",
    },
    {
        img: "https://source.unsplash.com/collection/41715229",
        description: "BYM sistema de logistica.",
        link: "https://bym.sistemascv.com/",
    },

    {
        img: "https://source.unsplash.com/collection/1926732",
        description: "Colaboré en el desarrollo de Fe Total, el mejor sistema de apoyo para iglesias en Latinoamérica y el mundo.",
        link: "#",
    }
];

const socialprofils = {
    linkedin: "http://www.linkedin.com/in/EdkarRodriguez",
    twitter: "https://twitter.com/Edkarr?t=ov4zrLF0RuuNVj3gDjTQUg&s=08",
};
export {
    meta,
    dataabout,
    dataportfolio,
    worktimeline,
    skills,
    services,
    introdata,
    socialprofils,
    logotext,
};